import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box } from 'rebass';
import { Button } from "@material-ui/core";

interface Post {
  slug: string;
  title: string;
  date: string;
}

const BlogList: React.FC = () => {
  const history = useNavigate();

  const posts: Post[] = [
    { slug: 'diprobot-performance', title: 'Performance vs Lump-Sum Investment', date: "November 11, 2024" },
    { slug: 'performance-during-flat-market', title: 'Performance in a flat market', date: "October 18, 2024" },
    { slug: 'switching-providers', title: 'Switching Exchange Providers', date: "August 6, 2024" },
    // Add more posts here as needed
  ];

  return (
    <Box p={4} maxWidth={1000} m="auto" fontSize="2rem">
      <Button variant="outlined" onClick={() => history("/")}>Home</Button>
      <h2>Blog Posts</h2>
      <ul style={{listStyleType: "none", paddingInlineStart: "0"}}>
        {posts.map(post => (
          <li key={post.slug} style={{marginBottom: "2rem", outline: "1px solid", padding: "1rem"}}>
            <Link style={{textDecoration: "none"}} to={`/blog/${post.slug}`}>{post.title} <p style={{fontSize: "1.5rem", margin:0, color: "white"}}>{post.date}</p></Link>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default BlogList;
