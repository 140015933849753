import React from 'react'
import './App.css'

import { HashRouter as Router, Route, Routes } from "react-router-dom";

import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from './contexts/authContext'
import UserConfigProvider from './contexts/userConfigContext'
import PriceProvider from "./contexts/priceContext";

import SignIn from './routes/auth/signIn'
import SignUp from './routes/auth/signUp'
import VerifyCode from './routes/auth/verify'
import RequestCode from './routes/auth/requestCode'
import ForgotPassword from './routes/auth/forgotPassword'
import ChangePassword from './routes/auth/changePassword'
import Landing from './routes/landing'
import Home from './routes/home'
import Gemini from './routes/gemini'
import Binance from "./routes/binance"
import Coinbase from './routes/coinbase'
import Account from './routes/account'
import HowItWorks from "./routes/how-it-works"
import Configuration from "./routes/configuration"
import InTheNews from "./routes/in-the-news"
import BlogList from './components/blogList';
import Blog from './components/blog';

let darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#4caf50",
      light: "#80e27e",
      dark: "#087f23",
      contrastText: "#fff",
    },
    secondary: {
      main: "#AF4C4C",
      light: "#ffb04c",
      dark: "#bc5100",
    },
  },
});
darkTheme = responsiveFontSizes(darkTheme)

const SignInRoute: React.FunctionComponent = () => (
  <Router>
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/verify" element={<VerifyCode />} />
      <Route path="/requestcode" element={<RequestCode />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/how-it-works" element={<HowItWorks />} />
      <Route path="/in-the-news" element={<InTheNews />} />
      <Route path="/blog" element={<BlogList />} />
      <Route path="/blog/:slug" element={<Blog />} />
      <Route path="/" element={<Landing />} />
    </Routes>
  </Router>
);

const MainRoute: React.FunctionComponent = () => (
  <Router>
    <Routes>
      <Route path="/changepassword" element={<ChangePassword />} />
      <Route path="/gemini" element={<Gemini />} />
      <Route path="/binance" element={<Binance />} />
      <Route path="/coinbase" element={<Coinbase />} />
      <Route path="/account" element={<Account />} />
      <Route path="/how-it-works" element={<HowItWorks />} />
      <Route path="/configuration" element={<Configuration />} />
      <Route path="/in-the-news" element={<InTheNews />} />
      <Route path="/blog" element={<BlogList />} />
      <Route path="/blog/:slug" element={<Blog />} />
      <Route path="/" element={<Home />} />
    </Routes>
  </Router>
);

const App = () => (
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <AuthProvider>
      <AuthIsSignedIn>
        <UserConfigProvider>
          <PriceProvider>
            <MainRoute />
          </PriceProvider>
        </UserConfigProvider>
      </AuthIsSignedIn>
      <AuthIsNotSignedIn>
        <SignInRoute />
      </AuthIsNotSignedIn>
    </AuthProvider>
  </ThemeProvider>
);

export default App
