import React, { useState, useContext, useMemo, useEffect } from "react";
import { getUserConfiguration } from "../libs/api";
import { AuthContext } from "./authContext";
import { Loading } from "../components/loading";

export interface UserConfiguration {
  userConfiguration: { [key: string]: any };
}

const defaultState = {
  userConfiguration: {},
};

export const UserConfigContext = React.createContext(defaultState);

async function fetchUserConfiguration() {
  try {
    const userConfiguration = await getUserConfiguration();
    return userConfiguration;
  } catch (e) {
    console.error("Error fetching user configuration:", e);
    return false;
  }
}

const UserConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useContext(AuthContext);
  const [userConfiguration, setUserConfiguration] = useState<{
    [key: string]: any;
  } | boolean>({});
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (Object.entries(auth?.sessionInfo || {}).length === 0) {
        return;
      }

      if (!isFetching && Object.keys(userConfiguration).length === 0) {
        setIsFetching(true);
        const config = await fetchUserConfiguration();
        setUserConfiguration(config);
        setIsFetching(false);
      }
    }
    fetchData();
  }, [auth?.sessionInfo, isFetching, userConfiguration]);

  const state = {
    userConfiguration,
  };
  if (isFetching) {
    return (
      <>
        <p>Loading user configuration...</p>
        <Loading />
      </>
    )
  }
  return (
    <UserConfigContext.Provider value={state}>
      {children}
    </UserConfigContext.Provider>
  );
};

export default UserConfigProvider;
