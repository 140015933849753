import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Card } from "@material-ui/core";

export default function HowItWorks() {
  const history = useNavigate();

  return (
    <Grid container>
      <Box maxWidth="1000px" m={"0 auto"}>
        <Box ml={2} mt={2} mb={5}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => history(-1)}
          >
            GO BACK
          </Button>
        </Box>
        <Grid container direction="column" justify="center" alignItems="center">
          <Box p={2} maxWidth="1000px" minWidth="100%">
            <Card>
              <img
                src="images/mascot.jpg"
                alt="mascot"
                style={{ maxWidth: "100%", minWidth: "100%" }}
              />
              <Box mb={2} ml={2}>
                <Typography variant="h4">Robot watches the charts.</Typography>
              </Box>
            </Card>
          </Box>
          <Box p={2} maxWidth="1000px">
            <Card>
              <img
                src="images/open-limit-orders.png"
                alt="visual explanation of limit-buy order"
                style={{ maxWidth: "100%" }}
              />
              <Box mb={2} p={2}>
                <Typography variant="h4">
                  He keeps{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.investopedia.com/terms/b/buy-limit-order.asp"
                  >
                    limit-buy orders
                  </a>{" "}
                  open at intervals below the price.
                </Typography>
              </Box>
            </Card>
          </Box>
          <Box p={2} maxWidth="1000px">
            <Card>
              <img
                src="images/buy-when-the-price-is-dropping.png"
                alt="chart of buying during downturns"
                style={{ maxWidth: "100%" }}
              />
              <Box mb={2} p={2}>
                <Typography variant="h4">
                  If the orders get executed or cancelled, he sets up new
                  orders.
                </Typography>
              </Box>
            </Card>
          </Box>
          <Box p={2} maxWidth="1000px">
            <Card>
              <Box mb={2} p={2}>
                <Box mb={1}>
                  <Typography variant="h4">In more detail</Typography>
                </Box>

                <Typography>
                  If you think an asset's price will go up in the long run, but
                  might dip in the short run, you might open some limit-buy
                  orders at intervals below the current price. This bot
                  automates the task of keeping these orders open, following the
                  price as it moves.
                </Typography>
                <Box mt={2}>
                  <img
                    src="images/buy-history-example.png"
                    alt="chart of buy history showing profitable buys"
                    style={{ maxWidth: "100%" }}
                  />
                </Box>
              </Box>
            </Card>
          </Box>

          <Box p={2} maxWidth="1000px">
            <Card>
              <Box mb={2} p={2}>
                <Box mb={1}>
                  <Typography variant="h4">
                    Build a diverse portfolio of coins
                  </Typography>
                </Box>

                <Typography>
                  Pick the coins you want, and this bot will get them at a good
                  price. Generally, it saves 10-20% versus buying at market
                  price.
                </Typography>
                <Box mt={2}>
                  <img
                    src="images/diverse-portfolio.png"
                    alt="pie chart of different coins making up your portfolio"
                    style={{ maxWidth: "100%" }}
                  />
                </Box>
              </Box>
            </Card>
          </Box>

          <Box>
            <Box>
              <Box ml={2} mt={4}>
                <Typography variant="h4">Risks</Typography>
              </Box>
              <Box p={2} maxWidth="1000px">
                <Card>
                  <img
                    src="images/risk_never_recovers.png"
                    alt="chart showing that if the price never recovers, this strategy doesn' help"
                    style={{ maxWidth: "100%" }}
                  />
                  <Box mb={2} ml={2}>
                    <Typography variant="h4">
                      If the asset's price keeps going down forever, and does
                      not ever recover, you lose.
                    </Typography>
                  </Box>
                </Card>
              </Box>
              <Box p={2} maxWidth="1000px">
                <Card>
                  <img
                    src="images/risk_should_have_bought_sooner.png"
                    alt="chart showing that if the price rises quickly, you miss out"
                    style={{ maxWidth: "100%" }}
                  />
                  <Box mb={2} ml={2}>
                    <Typography variant="h4">
                      If the asset's price goes up significantly in the short
                      term, you would have been better buying at market price
                      immediately, rather than waiting for a price drop.
                    </Typography>
                  </Box>
                </Card>
              </Box>
            </Box>

            <Box p={2} mt={2}>
              <Box mb={1}>
                <Typography variant="h4">Is this right for you?</Typography>
              </Box>
              <Typography>
                If you strongly believe that the value of an asset will go up
                over time, and can hold through downturns, and are generally
                financially good, this might be for you. It can be used as a
                complement to{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.investopedia.com/terms/d/dollarcostaveraging.asp"
                >
                  dollar-cost-averaging
                </a>{" "}
                that adds a little fun.{" "}
                <strong>
                  If you are not dollar-cost-averaging already, you should
                  probably set that up first.
                </strong>
              </Typography>
            </Box>

            <Box p={2} mt={2}>
              <Box mb={1}>
                <Typography variant="h4">How to get started</Typography>
              </Box>
              <Typography>
                You'll need an account at{" "}
                <a
                  href="https://www.coinbase.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Coinbase
                </a>. Then, you can{" "}
                <Link to="/account">add your account here.</Link> Make sure you
                have some fiat in your account, so the bot can place the trades.
              </Typography>
            </Box>

            <Box p={2} mt={2}>
              <Box mb={1}>
                <Typography variant="h4">Pricing</Typography>
              </Box>
              <Typography>It's free.</Typography>
              <Typography>
                There's enormous value in having a bunch of people ready to buy
                up any price dips, so our hope is that this project will be good
                for the crypto ecosystem as a whole.
              </Typography>
              <Typography>
                We have some ideas about sweet enhancements/features that might
                be worth some nominal fee in the future, but the normal version
                of this app will always be free.
              </Typography>
            </Box>

            <Box p={2} mt={2}>
              <Box mb={1}>
                <Typography variant="h4">Thoughts on Security</Typography>
              </Box>
              <Typography>
                Make sure to transfer your purchased coins off the exchange onto
                a hardware wallet on a regular basis. Always have 2-factor
                authentication on your account.
              </Typography>
              <Typography>
                <strong>
                  The bot does not have permission to withdraw your coins; he
                  can only create buy-orders on your account's behalf.
                </strong>
              </Typography>
            </Box>

            <Box p={2} mt={2}>
              <Box mb={1}>
                <Typography variant="h4">Disclaimer</Typography>
              </Box>
              <Typography>
                This is an experimental project, and your decision to use it is
                your own. We make no guarantees about your monetary performance
                or safety.
              </Typography>
            </Box>
          </Box>

          <Box mt={4}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() =>
                window.open(
                  "mailto:admin@diprobot.com?subject=about%20the%20bot"
                )
              }
            >
              Contact
            </Button>
            <Box mt={4} mb={5}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => history(-1)}
              >
                GO BACK
              </Button>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
}
