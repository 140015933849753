import React from "react";
import { Box, Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const history = useNavigate();
  
  return (
    <Box
      mt={4}
      p={4}
      style={{
        width: "100%",
        position: "static",
        bottom: "0",
      }}
    >
      <Box mt={2}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => history("blog")}
        >
          blog
        </Button>
      </Box>

      <Box mt={2}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => history("how-it-works")}
        >
          how it works
        </Button>
      </Box>

      <Box mt={2}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => history("in-the-news")}
        >
          DipRobot in the news
        </Button>
      </Box>

      <Box mt={2}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() =>
            window.open("mailto:admin@diprobot.com?subject=bot%20feedback")
          }
        >
          Contact
        </Button>
      </Box>
    </Box>
  );
};
