import moment from "moment";
import { getTotalSpent } from "./math";

export const ENV = process.env.NODE_ENV;

const getAuthHeader = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem("idToken")}`,
  };
};

const baseUrls = {
  "dev": "https://c9wha0m6g3.execute-api.us-east-1.amazonaws.com/dev/",
  "prod": "https://e0hmh7l4sl.execute-api.us-east-1.amazonaws.com/prod/"
};

export const endpoints: { [key: string]: any } = {
  setApiKey: {
    development: baseUrls.dev + "set-api-key",
    production:
      baseUrls.prod + "set-api-key",
  },
  getPortfolio: {
    development: baseUrls.dev + "get_portfolio",
    production:
      baseUrls.prod + "get_portfolio",
  },
  getUserConfiguration: {
    development:
      baseUrls.dev + "get-user-configuration",
    production:
      baseUrls.prod + "get-user-configuration",
  },
  setConfiguration: {
    development:
      baseUrls.dev + "set-configuration",
    production:
      baseUrls.prod + "set-configuration",
  },
  checkCoinbaseApiKey: {
    development:
      baseUrls.dev + "check-coinbase-api-key",
    production:
      baseUrls.prod + "check-coinbase-api-key",
  },
  checkGeminiApiKey: {
    development:
      baseUrls.dev + "check-gemini-api-key",
    production:
      baseUrls.prod + "check-gemini-api-key",
  },
  checkBinanceApiKey: {
    development:
      baseUrls.dev + "check-binance-api-key",
    production:
      baseUrls.prod + "check-binance-api-key",
  },
  getPrice: {
    development:
      baseUrls.dev + "get_price",
    production:
      baseUrls.prod + "get_price",
  },
  getOpenOrders: {
    development:
      baseUrls.dev + "get_open_orders",
    production:
      baseUrls.prod + "get_open_orders",
  },
  getTradesList: {
    development:
      baseUrls.dev + "get_trades_list",
    production:
      baseUrls.prod + "get_trades_list",
  },
  getCompletedBuys: {
    development:
      baseUrls.dev + "get_completed_buys",
    production:
      baseUrls.prod + "get_completed_buys",
  },
  getConfiguration: {
    development:
      baseUrls.dev + "configuration",
    production:
      baseUrls.prod + "configuration",
  },
  getStats: {
    development:
      baseUrls.dev + "stats",
    production:
      baseUrls.prod + "stats",
  },
  getTradingPairs: {
    development:
      baseUrls.dev + "trading_pairs",
    production:
      baseUrls.prod + "trading_pairs",
  },
  getAccountStatus: {
    development:
      baseUrls.dev + "get_account_status",
    production:
      baseUrls.prod + "get_account_status",
  },
  setHasInsufficientFunds: {
    development:
      baseUrls.dev + "set_has_insufficient_funds",
    production:
      baseUrls.prod + "set_has_insufficient_funds",
  },
  setHasConnectionProblem: {
    development:
      baseUrls.dev + "reset_connection_problem",
    production:
      baseUrls.prod + "reset_connection_problem",
  },
  resetHasOrderSizeProblem: {
    development:
      baseUrls.dev + "reset_order_size_problem",
    production:
      baseUrls.prod + "reset_order_size_problem",
  },
  cancelOpenOrders: {
    development:
      baseUrls.dev + "cancel_open_orders",
    production:
      baseUrls.prod + "cancel_open_orders",
  },
  getDollarsSpentLastNumDays: {
    development:
      baseUrls.dev + "get_dollars_spent_last_num_days",
    production:
      baseUrls.prod + "get_dollars_spent_last_num_days",
  },
};

export const setApiKey = ({
  secret,
  key,
  user,
  exchangeName,
}: {
  secret: string;
  key: string;
  user: string;
  exchangeName: string;
}) => {
  return fetch(`${endpoints.setApiKey[ENV]}`, {
    method: "post",
    headers: { "Content-Type": "application/json", ...getAuthHeader() },
    body: JSON.stringify({
      secret,
      key,
      user,
      exchangeName,
    }),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.error(error);
      }
    );
};

export const getPortfolio = (selectedExchange: string) => {
  return fetch(`${endpoints.getPortfolio[ENV]}?exchangeName=${selectedExchange}`, {
    headers: { "Content-Type": "application/json", ...getAuthHeader() },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.error(error);
      }
    );
};

export const getUserConfiguration = () => {
  return fetch(`${endpoints.getUserConfiguration[ENV]}`, {
    headers: { "Content-Type": "application/json", ...getAuthHeader() },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.error(error);
        return false;
      }
    );
};

export const setConfiguration = ({
  configuration,
}: {
  configuration: Object;
}) => {
  return fetch(`${endpoints.setConfiguration[ENV]}`, {
    method: "post",
    headers: { "Content-Type": "application/json", ...getAuthHeader() },
    body: JSON.stringify({
      configuration,
    }),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.error(error);
      }
    );
};

export const getOpenOrders = ({
  tickers,
  selectedExchange,
}: {
  tickers: string[];
  selectedExchange: string;
}) =>
  fetch(
    `${endpoints.getOpenOrders[ENV]}?tickers=${tickers.join(
      "%2C"
    )}&exchangeName=${selectedExchange}`,
    {
      headers: { "Content-Type": "application/json", ...getAuthHeader() },
    }
  )
    .then((res) => res.json())
    .then(
      (result: {
        [ticker: string]: { timestampms?: moment.MomentInput; time?: string }[];
      }) => {
        const openOrders: { [ticker: string]: any } = {};
        Object.entries(result).map(
          ([ticker, trades]) =>
          (openOrders[ticker] = trades?.map((trade) => ({
            ...trade,
            datetime: moment(trade.timestampms || trade.time).format(
              "MM/DD h:mm a"
            ),
          })))
        );
        return openOrders;
      }
    );
// .then(result => {
//   const trades = Object.values(result) as {
//     ticker: string;
//     result: any[];
//   }[];
//   let tradesObj: { [key: string]: any } = {};
//   for (let i = 0; i < trades.length; i++) {
//     const trade = trades[i];
//     tradesObj[trade.ticker] = trade.result;
//   }
// })
// .catch((e) => {
//   console.error(e);
//   return { ticker: ticker, result: [] };
// });

export const getPrices = (tickers: string[], exchangeName: string) => {
  return fetch(
    `${endpoints.getPrice[ENV]}?tickers=${tickers.join("%2C")}&exchangeName=${exchangeName}`,
    {
      headers: { "Content-Type": "application/json", ...getAuthHeader() },
    }
  )
    .then((res) => res.json());
};

export const getTradingPairs = (exchangeName: string) => {
  return fetch(
    `${endpoints.getTradingPairs[ENV]}?exchangeName=${exchangeName}`,
    {
      headers: { "Content-Type": "application/json", ...getAuthHeader() },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        if (Array.isArray(result)) {
          return result;
        }
        return [];
      },
      (error) => {
        console.error(error);
        return [];
      }
    )
    .catch((e) => {
      console.error(e);
      return [];
    });
};

export const checkGeminiApiKey = () => {
  return fetch(`${endpoints.checkGeminiApiKey[ENV]}`, {
    headers: { "Content-Type": "application/json", ...getAuthHeader() },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.error(error);
        return { has_key: false };
      }
    )
    .catch((e) => {
      console.error(e);
      return { has_key: false };
    });
};

export const checkBinanceApiKey = () => {
  return fetch(`${endpoints.checkBinanceApiKey[ENV]}`, {
    headers: { "Content-Type": "application/json", ...getAuthHeader() },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.error(error);
      }
    )
    .catch((e) => {
      console.error(e);
      return { has_key: false };
    });
};

export const checkCoinbaseApiKey = () => {
  return fetch(`${endpoints.checkCoinbaseApiKey[ENV]}`, {
    headers: { "Content-Type": "application/json", ...getAuthHeader() },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.error(error);
      }
    )
    .catch((e) => {
      console.error(e);
      return { has_key: false };
    });
};


const getStatsForTrades = (trades: any) => {
  const firstTrade = trades[0];
  if (!firstTrade) {
    return {
      averagePrice: 0,
      daysSinceFirstPurchase: 0,
      daysSinceLastPurchase: 0,
      averageNumberOfDaysBetweenTrades: 0,
    }
  }
  const timestamp = firstTrade.timestampms || firstTrade.time;
  const dateOfFirstTrade = moment(timestamp);
  const daysSinceFirstPurchase = moment().diff(dateOfFirstTrade, "days");

  const lastTrade = trades[trades.length - 1];
  const dateOfLastTrade = moment(lastTrade.time);
  const daysSinceLastPurchase = moment().diff(dateOfLastTrade, "days");


  const averageNumberOfDaysBetweenTrades = Math.round(daysSinceFirstPurchase / trades.length);

  const stats = {
    averagePrice: 0,
    daysSinceFirstPurchase,
    daysSinceLastPurchase,
    averageNumberOfDaysBetweenTrades,
  };

  const totalCoinAmount = trades.reduce((a: number, b: { amount: string }) => {
    return a + parseFloat(b.amount);
  }, 0);

  if (totalCoinAmount === 0) {
    return stats;
  }

  const totalSpent = getTotalSpent(trades);

  stats.averagePrice = (totalSpent / totalCoinAmount);

  return stats;
};

export const getTradeHistory = ({
  tickers,
  selectedExchange = "gemini",
}: {
  tickers: string[];
  selectedExchange?: string;
}) =>
  fetch(
    `${endpoints.getCompletedBuys[ENV]}?tickers=${tickers.join(
      "%2C"
    )}&exchangeName=${selectedExchange}`,
    {
      headers: { "Content-Type": "application/json", ...getAuthHeader() },
    }
  )
    .then((res) => res.json())
    .then(
      (result: { [ticker: string]: any }[]) => {
        const completedBuys: {
          [ticker: string]: { stats: { [key: string]: any }; trades: any[] };
        } = {};
        // eslint-disable-next-line array-callback-return
        Object.entries(result)?.map(([ticker, trades]) => {
          completedBuys[ticker] = {
            trades: [
              ...trades
                .sort((a: { timestampms: any; time: any; }, b: { timestampms: any; time: any; }) => {
                  return moment(a.timestampms || a.time) < moment(b.timestampms || b.time) ? -1 : 1
                })
                .map((trade: { timestampms: any; time: any }) => ({
                  ...trade,
                  datetime: moment(trade.timestampms || trade.time).format(
                    "MM/DD h:mm a"
                  ),
                })),
            ],
            stats: getStatsForTrades(trades),
          };
        });

        return completedBuys;
      },
      (error) => {
        console.error(error);
      }
    );

export const getAccountStatus = (exchangeName: string) => {
  return fetch(
    `${endpoints.getAccountStatus[ENV]}?exchangeName=${exchangeName}`,
    {
      headers: { "Content-Type": "application/json", ...getAuthHeader() },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.error(error);
        return false;
      }
    );
};

export const setHasInsufficientFunds = (exchangeName: string) => {
  return fetch(
    `${endpoints.setHasInsufficientFunds[ENV]}?exchangeName=${exchangeName}`,
    {
      method: "post",
      headers: { "Content-Type": "application/json", ...getAuthHeader() },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.error(error);
      }
    );
};

export const resetHasOrderSizeProblem = (exchangeName: string) => {
  return fetch(
    `${endpoints.resetHasOrderSizeProblem[ENV]}?exchangeName=${exchangeName}`,
    {
      method: "post",
      headers: { "Content-Type": "application/json", ...getAuthHeader() },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.error(error);
      }
    );
};

export const resetConnectionAccountStatus = (exchangeName: string) => {
  return fetch(
    `${endpoints.setHasConnectionProblem[ENV]}?exchangeName=${exchangeName}`,
    {
      method: "post",
      headers: { "Content-Type": "application/json", ...getAuthHeader() },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.error(error);
      }
    );
};

export const cancelOpenOrders = (ticker: string, exchangeName: string) => {
  return fetch(
    `${endpoints.cancelOpenOrders[ENV]}?exchangeName=${exchangeName}`,
    {
      method: "post",
      headers: { "Content-Type": "application/json", ...getAuthHeader() },
      body: JSON.stringify({ ticker }),
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.error(error);
      }
    );
};

export const getDollarsSpentLastNumDays = (
  ticker: string,
  exchangeName: string,
  numDays: number
) => {
  return fetch(
    `${endpoints.getDollarsSpentLastNumDays[ENV]}?ticker=${ticker}&exchangeName=${exchangeName}&numDays=${numDays}`,
    {
      headers: { "Content-Type": "application/json", ...getAuthHeader() },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.error(error);
      }
    );
};
