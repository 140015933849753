import React, { useState, useEffect, useContext } from "react";
import { getPrices } from "../libs/api";
import { AuthContext } from "./authContext";
import { UserConfigContext } from "./userConfigContext";
import { Loading } from "../components/loading";

export interface Price {
  prices: {[key:string]: any};
}

const defaultState = {
  prices: {},
};

export const PriceContext = React.createContext(defaultState);

const PriceProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useContext(AuthContext);
  const userConfig: { [key: string]: any } = useContext(UserConfigContext);
  const [prices, setPrices] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    async function fetchPrices(userConfig: { [key: string]: any }) {
      const _prices: { [key: string]: any } = {};

      Object.entries(userConfig).map(async ([exchangeName, coins]) => {
        _prices[exchangeName] = {};

        const tickers = Object.keys(coins);

        const prices = (await getPrices(tickers, exchangeName)) as {
          [key: string]: any;
        };
        Object.entries(prices).map(
          ([ticker, price]) => (_prices[exchangeName][ticker] = price)
        );
      });

      setPrices(_prices);
    }

    if (Object.entries(auth?.sessionInfo as {}).length === 0) {
      return;
    }
    if (Object.entries(userConfig.userConfiguration).length === 0) {
      return;
    }
    if (Object.entries(prices).length === 0) {
      fetchPrices(userConfig.userConfiguration);
    }
    // const intervalId = setInterval(() => {
    //   fetchPrices(userConfig.userConfiguration);
    // }, 30000);
    // return () => clearInterval(intervalId);
  }, [prices, setPrices, auth?.sessionInfo, userConfig.userConfiguration]);

  const state = {
    prices,
  };
  
  if (Object.entries(auth?.sessionInfo as {}).length === 0) {
    return (
      <>
        <p>Loading authorization...</p>
        <Loading />
      </>
    );
  }
  if (Object.entries(userConfig.userConfiguration).length === 0) {
    return (
      <>
        <p>Loading user configuration...</p>
        <Loading />
      </>
    );
  }
  if (Object.entries(prices).length === 0) {
    return (
      <>
        <p>Fetching prices...</p>
        <Loading />
      </>
    );
  }

  return (
    <PriceContext.Provider value={state}>{children}</PriceContext.Provider>
  );
};

export default PriceProvider;
