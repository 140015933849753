import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from 'rebass';
import { Button } from "@material-ui/core";

const Blog: React.FC = () => {
  const history = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<string | null>(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        // Dynamically import the selected blog post
        await import(`./blog/${slug}.md`)
      .then((module) => {
        setPost(module.default)
        console.log("module default", module.default)
      })
      .catch((error) => console.error("Error loading markdown file:", error));
      } catch (error) {
        console.error('Error loading the post:', error);
      }
    };

    fetchPost();
  }, [slug]);

  if (!post) return <div>Loading...</div>;
  
  return (
    <Box p={4} maxWidth={1000} m="auto" fontSize="1.5rem">
      <Button variant="outlined" onClick={() => history("/blog")}>Back</Button>
      <Box mt={3} mb={3}><ReactMarkdown>{post}</ReactMarkdown></Box>
      
      <Button variant="outlined" onClick={() => history("/blog")}>Back</Button>
    </Box>
  );
};

export default Blog;
